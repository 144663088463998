// noinspection SpellCheckingInspection

import { extend } from 'lodash-es';
import { Permissions } from 'src/app/shared/auth/permissions';

export const environment = (() => {
  let env = {
    name: 'base',
    companyName: 'Kochava',
    productName: 'AIM Platform',
    showDebugInfo: true,
    showEnvironmentInfo: true,
    angularProductionMode: false,
    auth0Domain: 'auth.aimplatform.io',
    auth0ClientID: 'qaf4sjdpBjuEEL2IVvCURCr3LFeXSSHy',
    auth0Audience: 'https://api.aimplatform.io',
    // tslint:disable-next-line: max-line-length
    auth0Scope: `openid profile email ${Permissions.all.join(' ')}`,
    auth0DbConnectionId: 'con_lxrehhl02RO1QbtQ',
    auth0CallbackURL: '',
    // Used to determine whether or not to add the authorization header to outgoing HTTP requests
    authDomainPattern: /^https?:\/\/(?:[a-z\d-]+\.)*(?:aimplatform\.io|kochava\.com|auth0\.com)/i,
    auth0SecurityAdminRole: 'rol_JrYbsp0K8mDP8Yo5',
    logLevel: 'warning', // debug | info | warning | error
    supportEmail: 'support@aimplatform.io',
    salesPhone: '+44 (0)20 3286 7404',
    salesPhonePlain: '442032867404',
    version: '241',
    apiBase: '',
    rateLimitingClientId: `portal-ui-${((window as any)._envConfig)?.name ?? 'base'}`,
    attributionApiBase: '',
    organicNetworkId: 'f93792f2-483d-4ca6-8577-0a2180bd4cec'
  };

  // Extend the base config with deployment-specific parameters
  if ((window as any)._envConfig) { env = extend(env, (window as any)._envConfig); }

  return env;
})();
